var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"display":"flex","flex-direction":"column"},attrs:{"height":"100%"}},[(_vm.promotionObj && _vm.promotionObj.banner)?_c('v-img',{staticClass:"eur-card-img",attrs:{"contain":"","height":"200","max-height":"200","src":_vm.promotionObj.banner.href}}):_vm._e(),_c('v-card-title',{staticClass:"eur-card-title"},[_vm._v(" "+_vm._s(_vm.promotionObj.name)+" ")]),_c('v-card-subtitle',{staticClass:"eur-card-subtitle"},[(_vm.promotionObj.download)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","href":_vm.promotionObj.download.href,"target":"_blank","rel":"noreferrer"}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-paperclip")])],1)]}}],null,false,849608650)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Rebate Details")))])]):_vm._e()],1),_c('v-card-text',{staticClass:"ma-0 pa-0 grow"},[_c('v-expansion-panels',{attrs:{"flat":"","tile":"","multiple":"","focusable":""}},[_c('v-expansion-panel',{attrs:{"readonly":""}},[_c('v-expansion-panel-header',{attrs:{"color":"#f8f8f8","dense":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.promotionObj.download)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"float-right mr-n1",attrs:{"icon":"","href":_vm.promotionObj.download.href,"target":"_blank","rel":"noreferrer"}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-paperclip")])],1)]}}],null,false,3216858407)},[_c('span',[_vm._v("Rebate Details")])]):_vm._e(),_c('v-icon',{attrs:{"color":"error"}})]},proxy:true}])},[_vm._v(" Promotion #: "+_vm._s(_vm.promotionObj.promotionKey)+" ")])],1),_c('v-expansion-panel',{on:{"change":function($event){return _vm.getProducts(_vm.promotionObj)}}},[_c('v-expansion-panel-header',{attrs:{"color":"#f8f8f8"}},[_vm._v(_vm._s(_vm.$i18n.translate("Eligible Models")))]),_c('v-expansion-panel-content',{staticStyle:{"background-color":"#eee"}},[_c('v-data-table',{attrs:{"hide-default-footer":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.expandedProducts[_vm.promotionObj.id],"server-items-length":_vm.expandedProducts[_vm.promotionObj.id] ? _vm.expandedProducts[_vm.promotionObj.id].length : 1,"no-data-text":_vm.$i18n.translate('No eligible products')},scopedSlots:_vm._u([{key:"item.payout",fn:function(ref){
var item = ref.item;
return [(item.promotionProductPayouts && item.promotionProductPayouts.length > 0)?_c('ol',_vm._l((item.promotionProductPayouts),function(promotionProductPayout,i){return _c('span',{key:i,staticClass:"text-no-wrap"},[(
                      promotionProductPayout.payoutDescription && promotionProductPayout.payoutDescription.length > 0
                    )?_c('div',[_vm._v(" "+_vm._s(promotionProductPayout.payoutDescription)+" ")]):_c('div',[_vm._v(" "+_vm._s(promotionProductPayout.payoutType.name == "FLAT_AMOUNT_PER_UNIT_CLAIMED" ? "$ " + Number(promotionProductPayout.payoutAmount).toFixed(2) : "")+" "+_vm._s(promotionProductPayout.payoutType.name == "PERCENT_CLAIM_AMOUNT_CLAIMED" || promotionProductPayout.payoutType.name == "PERCENT_PRODUCT_AMOUNT_CLAIMED" ? Number(promotionProductPayout.payoutAmount).toFixed(2) + " %" : "")+" "+_vm._s(promotionProductPayout.payoutType.name == "PRODUCT" ? _vm.$i18n.translate("FREE Item") : "")+" ")])])}),0):_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("No Payout")))])]}}])})],1)],1),_c('v-expansion-panel',{attrs:{"value":0}},[_c('v-expansion-panel-header',{attrs:{"color":"#f8f8f8"}},[_vm._v(_vm._s(_vm.$i18n.translate("Important Dates")))]),_c('v-expansion-panel-content',{staticStyle:{"background-color":"#eee"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((_vm.promotionObj.promotionDeadlines),function(promotionDeadline){return [(promotionDeadline.submittable && _vm.isStillClaimable(promotionDeadline))?_c('tr',{key:'1_' + promotionDeadline.id},[_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$i18n.translate("Start Date")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateClient")(promotionDeadline.effectiveDate ? promotionDeadline.effectiveDate : _vm.promotion.effectiveDate,"MM/DD/YYYY", _vm.selectedClient))+" ")])]):_vm._e(),(promotionDeadline.submittable && _vm.isStillClaimable(promotionDeadline))?_c('tr',{key:'2_' + promotionDeadline.id},[_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$i18n.translate("End Date")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateClient")(promotionDeadline.expirationDate ? promotionDeadline.expirationDate : _vm.promotion.expirationDate,"MM/DD/YYYY", _vm.selectedClient))+" ")])]):_vm._e(),(
                      promotionDeadline.submittable &&
                        _vm.isStillClaimable(promotionDeadline) &&
                        _vm.promotion.promotionType.claimable
                    )?_c('tr',{key:'3_' + promotionDeadline.id},[_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$i18n.translate("Claim Deadline")))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDateClient")(promotionDeadline.submissionDeadlineDate,"MM/DD/YYYY", _vm.selectedClient))+" ")])]):_vm._e()]})],2)]},proxy:true}])})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"#f8f8f8"}},[_vm._v(_vm._s(_vm.$i18n.translate("Rebate Details")))]),_c('v-expansion-panel-content',{staticStyle:{"background-color":"#eee"}},[_c('p',{staticClass:"pa-6",domProps:{"innerHTML":_vm._s(_vm.promotionObj.description)}})])],1)],1)],1),(_vm.showClaimButton)?_c('v-divider'):_vm._e(),_c('v-card-actions',[(_vm.showClaimButton && _vm.promotionObj.promotionType.claimable == true)?_c('v-btn',{staticClass:"mt-5 mb-5 text-center",attrs:{"block":"","to":_vm.promotionObj.promotionType.promotionTypeKey == _vm.env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_BRAVIA
          ? { name: 'eurBraviaWarranty' }
          : _vm.promotionObj.promotionType.promotionTypeKey == _vm.env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_WHITEGLOVE
          ? { name: 'eurWhiteglove' }
          : { name: 'eurClaim', params: { id: _vm.promotionObj.id } },"color":"primary"}},[_vm._v(" "+_vm._s(_vm.promotionObj.promotionType.promotionTypeKey == "IPSA_END_USER_REGISTER" ? _vm.$i18n.translate("Register") : _vm.$i18n.translate("Claim"))+" "),_c('GoogleReCaptcha',{attrs:{"showLegend":false}})],1):_vm._e(),(_vm.showClaimButton && _vm.promotionObj.promotionType.claimable == false)?_c('v-btn',{staticClass:"mt-5 mb-5 text-center",attrs:{"block":"","href":['9000018840', '9000018778'].includes(_vm.promotion.promotionKey)
          ? 'https://alphauniverse.com/dealerlocator/'
          : _vm.resellerHref,"target":"_blank","rel":"noreferrer","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("Find a Reseller"))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card height="100%" style="display: flex; flex-direction: column">
    <v-img
      contain
      class="eur-card-img"
      v-if="promotionObj && promotionObj.banner"
      height="200"
      max-height="200"
      :src="promotionObj.banner.href"
    >
    </v-img>

    <v-card-title class="eur-card-title">
      {{ promotionObj.name }}
    </v-card-title>
    <v-card-subtitle class="eur-card-subtitle">
      <v-tooltip bottom v-if="promotionObj.download">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="float-right" :href="promotionObj.download.href" target="_blank" rel="noreferrer"
            ><v-icon v-bind="attrs" v-on="on">mdi-paperclip</v-icon></v-btn
          >
        </template>
        <span>{{ $i18n.translate("Rebate Details") }}</span>
      </v-tooltip>
    </v-card-subtitle>

    <v-card-text class="ma-0 pa-0 grow">
      <v-expansion-panels flat tile multiple focusable>
        <v-expansion-panel readonly>
          <v-expansion-panel-header color="#f8f8f8" dense>
            Promotion #: {{ promotionObj.promotionKey }}

            <template v-slot:actions>
              <v-tooltip bottom v-if="promotionObj.download">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="float-right mr-n1"
                    :href="promotionObj.download.href"
                    target="_blank"
                    rel="noreferrer"
                    ><v-icon v-bind="attrs" v-on="on">mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>Rebate Details</span>
              </v-tooltip>
              <v-icon color="error"> </v-icon>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
        <v-expansion-panel @change="getProducts(promotionObj)">
          <v-expansion-panel-header color="#f8f8f8">{{ $i18n.translate("Eligible Models") }}</v-expansion-panel-header>
          <v-expansion-panel-content style="background-color: #eee">
            <v-data-table
              hide-default-footer
              :loading="loading"
              :headers="headers"
              :items="expandedProducts[promotionObj.id]"
              :server-items-length="expandedProducts[promotionObj.id] ? expandedProducts[promotionObj.id].length : 1"
              :no-data-text="$i18n.translate('No eligible products')"
            >
              <template v-slot:item.payout="{ item }">
                <ol v-if="item.promotionProductPayouts && item.promotionProductPayouts.length > 0">
                  <span
                    v-for="(promotionProductPayout, i) in item.promotionProductPayouts"
                    :key="i"
                    class="text-no-wrap"
                  >
                    <div
                      v-if="
                        promotionProductPayout.payoutDescription && promotionProductPayout.payoutDescription.length > 0
                      "
                    >
                      {{ promotionProductPayout.payoutDescription }}
                    </div>
                    <div v-else>
                      {{
                        promotionProductPayout.payoutType.name == "FLAT_AMOUNT_PER_UNIT_CLAIMED"
                          ? "$ " + Number(promotionProductPayout.payoutAmount).toFixed(2)
                          : ""
                      }}
                      {{
                        promotionProductPayout.payoutType.name == "PERCENT_CLAIM_AMOUNT_CLAIMED" ||
                        promotionProductPayout.payoutType.name == "PERCENT_PRODUCT_AMOUNT_CLAIMED"
                          ? Number(promotionProductPayout.payoutAmount).toFixed(2) + " %"
                          : ""
                      }}
                      {{ promotionProductPayout.payoutType.name == "PRODUCT" ? $i18n.translate("FREE Item") : "" }}
                    </div>
                  </span>
                </ol>
                <span v-else>{{ $i18n.translate("No Payout") }}</span>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :value="0">
          <v-expansion-panel-header color="#f8f8f8">{{ $i18n.translate("Important Dates") }}</v-expansion-panel-header>
          <v-expansion-panel-content style="background-color: #eee">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <template v-for="promotionDeadline in promotionObj.promotionDeadlines">
                    <tr
                      v-if="promotionDeadline.submittable && isStillClaimable(promotionDeadline)"
                      :key="'1_' + promotionDeadline.id"
                    >
                      <th class="text-right">{{ $i18n.translate("Start Date") }}</th>
                      <td>
                        {{
                          promotionDeadline.effectiveDate
                            ? promotionDeadline.effectiveDate
                            : promotion.effectiveDate | formatDateClient("MM/DD/YYYY", selectedClient)
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="promotionDeadline.submittable && isStillClaimable(promotionDeadline)"
                      :key="'2_' + promotionDeadline.id"
                    >
                      <th class="text-right">{{ $i18n.translate("End Date") }}</th>
                      <td>
                        {{
                          promotionDeadline.expirationDate
                            ? promotionDeadline.expirationDate
                            : promotion.expirationDate | formatDateClient("MM/DD/YYYY", selectedClient)
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        promotionDeadline.submittable &&
                          isStillClaimable(promotionDeadline) &&
                          promotion.promotionType.claimable
                      "
                      :key="'3_' + promotionDeadline.id"
                    >
                      <th class="text-right">{{ $i18n.translate("Claim Deadline") }}</th>
                      <td>
                        {{ promotionDeadline.submissionDeadlineDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="#f8f8f8">{{ $i18n.translate("Rebate Details") }}</v-expansion-panel-header>
          <v-expansion-panel-content style="background-color: #eee">
            <p class="pa-6" v-html="promotionObj.description" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-divider v-if="showClaimButton" />
    <v-card-actions>
      <v-btn
        block
        :to="
          promotionObj.promotionType.promotionTypeKey == env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_BRAVIA
            ? { name: 'eurBraviaWarranty' }
            : promotionObj.promotionType.promotionTypeKey == env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_WHITEGLOVE
            ? { name: 'eurWhiteglove' }
            : { name: 'eurClaim', params: { id: promotionObj.id } }
        "
        v-if="showClaimButton && promotionObj.promotionType.claimable == true"
        color="primary"
        class="mt-5 mb-5 text-center"
      >
        {{
          promotionObj.promotionType.promotionTypeKey == "IPSA_END_USER_REGISTER"
            ? $i18n.translate("Register")
            : $i18n.translate("Claim")
        }}
        <GoogleReCaptcha :showLegend="false" />
      </v-btn>
      <v-btn
        block
        :href="
          ['9000018840', '9000018778'].includes(promotion.promotionKey)
            ? 'https://alphauniverse.com/dealerlocator/'
            : resellerHref
        "
        target="_blank"
        rel="noreferrer"
        v-if="showClaimButton && promotionObj.promotionType.claimable == false"
        color="primary"
        class="mt-5 mb-5 text-center"
      >
        {{ $i18n.translate("Find a Reseller") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import GoogleReCaptcha from "@/gapp-components/components/display/GoogleReCaptcha.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { GoogleReCaptcha },
  name: "EurCard",
  props: {
    promotion: Object,
    showClaimButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      env: null,
      panels: [],
      loading: false,
      promotionObj: {},
      resellerHref: "https://pro.sony/ue_US/where-to-buy/products/",
      expandedProducts: []
    };
  },
  methods: {
    getProducts(promotion) {
      if (promotion.id in this.expandedProducts) {
        return;
      }
      this.loading = true;
      return this.$api
        .get("/api/promotions/" + promotion.id + "/public")
        .then(({ data }) => {
          this.expandedProducts[promotion.id] = data.promotionProducts.filter(pp => pp.effective);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateResellerHref() {
      let selectedLanguage = this.selectedLocale.languageType.name;
      if (this.promotionObj && this.promotionObj.country && selectedLanguage) {
        switch (this.promotionObj.country.name) {
          case "USA":
            this.resellerHref = "https://pro.sony/ue_US/where-to-buy/products/";
            break;
          case "CAN":
            if (this.selectedLocale) {
              switch (this.selectedLocale.languageType.name) {
                case "en":
                  this.resellerHref = "https://pro.sony/en_CA/where-to-buy/products/";
                  break;
                case "fr":
                  this.resellerHref = "https://pro.sony/qf_CA/where-to-buy/products/";
                  break;
                default:
                  this.resellerHref = "https://pro.sony/en_CA/where-to-buy/products/";
                  break;
              }
            } else {
              this.resellerHref = "https://pro.sony/en_CA/where-to-buy/products/";
            }
            break;
          default:
            this.resellerHref = "https://pro.sony/ue_US/where-to-buy/products/";
            break;
        }
      }
    },
    isStillClaimable(objectWithEffectivity) {
      let now = moment();
      let active = false;
      if (objectWithEffectivity.effectiveDate && objectWithEffectivity.submissionDeadlineDate) {
        active = now.isBetween(
          moment(objectWithEffectivity.effectiveDate),
          moment(objectWithEffectivity.submissionDeadlineDate)
        );
      } else if (objectWithEffectivity.effectiveDate) {
        active = now.isSameOrAfter(objectWithEffectivity.effectiveDate);
      } else if (objectWithEffectivity.submissionDeadlineDate) {
        active = now.isSameOrBefore(objectWithEffectivity.submissionDeadlineDate);
      } else {
        active = true;
      }
      return active;
    }
  },
  watch: {
    promotion() {
      this.promotionObj = this.promotion;
    },
    selectedLocale: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectedLocale) {
          this.updateResellerHref();
        }
      }
    }
  },
  created() {
    this.env = process.env;
    this.promotionObj = this.promotion;
    this.updateResellerHref();
  },
  mounted() {
    this.promotionObj = this.promotion;
  },
  computed: {
    ...mapGetters(["selectedLocale", "selectedClient"]),
    headers() {
      if (
        this.expandedProducts &&
        this.promotion &&
        this.expandedProducts[this.promotion.id] &&
        this.expandedProducts[this.promotion.id].every(elem =>
          elem.promotionProductPayouts.every(subelem => subelem.payoutType.name == "PRODUCT")
        )
      ) {
        return [{ text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false }];
      } else {
        return [
          { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false },
          { text: this.$i18n.translate("Rebate"), value: "payout", sortable: false }
        ];
      }
    }
  }
};
</script>
